@media screen and (min-width: 768px) {
  .content-container::-webkit-scrollbar {
    background: transparent;
  }

  .content-container::-webkit-scrollbar-thumb {
    background-color: white;
    width: 4px;
    border-radius: 4px;
  }
}
