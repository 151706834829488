.event-list::-webkit-scrollbar {
  background-color: black;
  width: 4px;
  border-radius: 4px;
}

.event-list::-webkit-scrollbar-thumb {
  background-color: white;
  width: 4px;
  border-radius: 4px;
}
